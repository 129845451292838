<template>
  <a-auto-complete
    v-model="targetCountry"
    :placeholder="placeholder"
    :filter-option="filterCountry"
    @keydown.enter.capture="onEnter"
    :data="options"
    :triggerProps="{
      popupContainer,
    }"
    @focus="handleFocus"
    @blur="handleBlur"
    @change="handleChange"
    ref="autoComplete"
  />
  <a-carousel
    v-if="carouselList.length && showCountryCarousel && !targetCountry"
    v-model:current="current"
    class="country-carousel"
    :auto-play="{ interval: 2000 }"
    show-arrow="never"
    direction="vertical"
    indicator-type="never"
  >
    <a-carousel-item
      class="country-carousel-item"
      v-for="country in carouselList"
      :key="country"
      @click="handleCarouselClick"
    >
      {{ $t(country) }}
    </a-carousel-item>
  </a-carousel>
</template>

<script setup lang="ts">
import { Ref } from 'vue';
import { ICountry } from '~~/@types/entities';
import { AutoComplete } from '@arco-design/web-vue';
import { useI18n } from 'vue-i18n';
interface IProps {
  targetCountry: string;
  popupContainer?: HTMLElement | null;
}
type IHotCountryList = ICountry & { id: string; priority: number };
const { t, locale } = useI18n();
const props = defineProps<IProps>();
const emits = defineEmits(['onExceedLimit', 'update:targetCountry']);

const current = ref(1);
const placeholder = ref('');
const showCountryCarousel = ref(true);
const cList = inject<() => IHotCountryList[]>('countryList');
const options = computed(() => {
  return (
    cList?.()?.map(c => ({
      ...c,
      value: locale.value === 'zh-CN' ? c.cnName : c.enName,
    })) || []
  );
});

const countryList = new Map(cList?.()?.map(c => [c.cnName, c]));
const targetCountry = computed({
  get: () => {
    return props.targetCountry;
  },
  set: (val: string[] | string | undefined | number[]) => {
    emits('update:targetCountry', val);
  },
});

const filterCountry = (iv: string, op: Ref<number | string>) => {
  const name = op.value;
  const item = countryList.get(name as string);
  if (typeof op.value === 'string' && op.value.includes?.(iv) && !countryList.get(iv)) {
    return true;
  }
  return ['cnName', 'enName', 'cnNamePinyin', 'cnNamePinyinInitial'].some(k =>
    item?.[k as keyof ICountry]?.toLocaleLowerCase()?.startsWith?.(iv?.toLocaleLowerCase())
  );
};

const onEnter = (e: KeyboardEvent) => {
  if (e.isComposing) {
    e.stopPropagation();
  }
};

const autoComplete = ref<typeof AutoComplete | null>(null);
const handleCarouselClick = () => {
  showCountryCarousel.value = false;
  autoComplete.value?.focus();
};

const handleFocus = () => {
  showCountryCarousel.value = false;
  placeholder.value = getCurrentCarouselName();
};
const handleBlur = () => {
  showCountryCarousel.value = true;
  placeholder.value = '';
};

const handleChange = (value: string) => {
  if (!value) {
    placeholder.value = getCurrentCarouselName();
  }
};

const carouselList = ref<string[]>(['印尼', '越南', '泰国', '巴西', '西班牙']);
const getCurrentCarouselName = () => {
  return t(carouselList.value[current.value - 1]) || t('印尼');
};
const getCurrentCarouselCnName = () => {
  return carouselList.value[current.value - 1] || '印尼';
};

onMounted(async () => {
  // const res = await fetch('/api/search/hot?platform=homepage_pc&channel=search_global');
  // carouselList.value = await res.json() as string[];
});

defineExpose({
  getCurrentCarouselCnName,
});
</script>

<style lang="less">
.country-select.arco-select-view-multiple.arco-select-view-size-medium .arco-select-view-input {
  line-height: 32px;
  height: 32px;
}
.arco-carousel.country-carousel {
  width: calc(100% - 200px);
  height: 60px;
  position: absolute;
  top: 0;
  left: 13px;
  .country-carousel-item {
    font-size: 20px;
    line-height: 60px;
    color: #86909c;
    cursor: text;
  }
}
@media screen and(max-width:800px) {
  .arco-carousel.country-carousel {
    width: calc(100% - 110px);
    height: 38px;
    position: absolute;
    top: 0;
    left: 13px;
    .country-carousel-item {
      font-size: 16px;
      line-height: 38px;
      color: #86909c;
      cursor: text;
    }
  }
}
</style>
